import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ImageSize, LazyImage } from "../image-upload";
import { UnstyledLink } from "../ui/unstyled-link";
import { getTruncatedText } from "../utils/get-truncated-text";
import { SearchModel } from "./search.model";

interface Props extends SearchModel {
    onOpen?: () => void;
    item: {
        id: number;
        image_key?: string;
        name: string;
        description?: string;
    }
}

export const TrackRenderer = ({
    item,
    onOpen,
}: Props) => {
    const classes = useStyles({});
    return <UnstyledLink to={`/t/${item.id}`} onClick={onOpen}>
        <a className={classes.profile}>
            <div className={classes.avatar}>
                <LazyImage
                    image_key={item.image_key}
                    className='image'
                    image_opt={{ size: ImageSize.large, aspect_ratio: 3 }}
                />
            </div>
            <div className={classes.content}>
                <Typography variant='overline'>Official Track</Typography>
                <Typography variant='h6'>{item.name}</Typography>
                <Typography variant='body1'>{getTruncatedText(item.description, 200)}</Typography>
            </div>
        </a>
    </UnstyledLink>
}

const useStyles = makeStyles(theme => createStyles({
    profile: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        '&:hover': {
            background: theme.palette.divider,
        },
    },
    avatar: {
        width: '100%',
        maxWidth: 200,
        display: 'block',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all .2s ease',
        '& .image': {
            width: '100%',
            objectFit: 'contain',
        }
    },
  
    content: {
        flex: 1,
        padding: theme.spacing(0, 2),
    }
}))