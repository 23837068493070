import { createStyles, DialogContent, IconButton, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import React from "react";
import { BasicDialog, BasicDialogTitle } from "../ui/dialog";
import { EmptyListComponent } from "../ui/empty-list.component";
import { LoadingDots } from "../ui/loading-dots";
import { ProfileRenderer } from "./profile-renderer";
import { SearchModel, SearchType } from "./search.model";
import { TrackRenderer } from "./track-renderer";
import { useSearch } from "./use-search";
import { VideoRenderer } from "./video-renderer";

type Props = {
    onClose: () => void;
}

export type SearchProps = {
    query: string;
    onClose: () => void;
    onLoading: (loading: boolean | 0) => void;
}

export const SearchDialog = ({
    onClose,
}: Props) => {
    const classes = useStyles({});
    const {
        loading,
        setSearchQuery,
        searchQuery,
        items,
    } = useSearch();

    const renderItem = (item: SearchModel) => {
        switch (item.type) {
            case SearchType.track:
                return <TrackRenderer
                    {...item}
                />
            case SearchType.profile:
                return <ProfileRenderer
                    {...item}
                />
            case SearchType.video:
                return <VideoRenderer
                    {...item}
                />
        }
    }

    return <BasicDialog
        id='search-dialog'
        max_width='lg'
        onClose={onClose}
        open={true}
        full_screen='md'>
        <BasicDialogTitle onClose={onClose} title='Search on edropin' />
        <DialogContent>
            <TextField
                placeholder={'Type to search...'}
                fullWidth
                className='bar'
                margin='normal'
                autoFocus
                onChange={e => setSearchQuery(e.target.value)}
                value={searchQuery}

                InputProps={{
                    startAdornment: <InputAdornment position='start'>
                        <SearchIcon />
                    </InputAdornment>,
                    endAdornment: searchQuery.length > 0 ? <InputAdornment position='end'>
                        <IconButton onClick={() => setSearchQuery('')}>
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment> : null
                }}
            />
            {items.map(item => <div
                className={classes.item}
                key={`${item.type}-${item.item.id}`}>
                {renderItem(item)}
            </div>)}
            {loading ? <LoadingDots /> : null}
            {!loading && items.length === 0 ? <EmptyListComponent
                icon={<SearchIcon />}
                message={searchQuery.length === 0 ? 'Type to start searching...' : 'No results found.'}
            /> : null}
        </DialogContent>
    </BasicDialog>
}

const useStyles = makeStyles(theme => createStyles({
    searchFilters: {
        paddingBottom: theme.spacing(1),
        '& .item': {
            margin: theme.spacing(.5),
        }
    },
    item: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
            borderBottom: 0,
        }
    }
}))