import { Button, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { environment } from "../../environment";
import { MicroCreditsVideo } from './micro-credits-video';

type Props = {
}

export const GuestLearnMore = ({
}: Props) => {
    const classes = useStyles({});
    return <div id='hero' className={classes.content}>
        <Container maxWidth='lg'>
            <MicroCreditsVideo />
            <Typography variant='h3' align='center' className={classes.title}>
                <strong>Want to learn more about Edropin?</strong>
            </Typography>
            <Typography variant='body1' align='center' className={classes.body}>
                Let us show you behind the curtains and tell you our story!
            </Typography>
            <div className={classes.buttons}>
                <Button size='large'
                    fullWidth
                    target='_blank'
                    href={`${environment.frontend_url}/about`}
                    className='join'
                    variant='text'
                    color='default'>Learn more about Edropin</Button>
            </div>
        </Container>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    content: {
        padding: theme.spacing(6, 0),
        background: theme.palette.primary.main,
        color: 'white',
    },
    image: {
        maxWidth: '100%',
    },
    title: {
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    body: {
        fontSize: '1.5em',
    },
    buttons: {
        paddingTop: theme.spacing(2),
        width: '100%',
        margin: '0 auto',
        maxWidth: 500,
        '& .join': {
            background: theme.palette.background.default,
            color: 'black',
            '&:hover': {
                background: 'white',
            }
        },
    },
    waitlist: {
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(0, 1),
        boxShadow: theme.shadows[4],
    }
}))