import { Box, Button, Grid, Typography } from "@material-ui/core";
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';
import NextIcon from '@material-ui/icons/ChevronRight';
import WatchHistoryIcon from '@material-ui/icons/History';
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { ActionType } from "../../action-manager/action-manager";
import { useKeysetPagination } from "../../apollo/networking/use-keyset-pagination";
import { useUser } from "../../auth/use-user";
import { VideoCard } from "../../components/video-card";
import { VideoCardFragment, VideoCardModel } from "../../models/video-card.model";
import { EmptyListComponent } from "../../ui/empty-list.component";
import { LoadingButton } from "../../ui/loading-button";
import { UnstyledLink } from "../../ui/unstyled-link";

type VideoUser = {
    id: number;
    updated_at: string;
    video: VideoCardModel;
}

export const WatchHistory = () => {
    const cursor = React.useMemo(() => new Date().toISOString(), []);
    const { manager } = useUser();
    const {
        loading,
        items,
        fetchMore,
        has_more,
    } = useKeysetPagination<VideoUser>({
        entity_name: 'video_user',
        fragment: `
            id
            updated_at
            video {
                ${VideoCardFragment}
            }
        `,
        query_inner: `where: {
            updated_at: {_lt: $cursor}
        }, order_by: {updated_at: desc}`,
        clause_outer: `$cursor: timestamptz!`,
        variables: {
            cursor,
        },
    })

    React.useEffect(() => {
        manager.insertAction(ActionType.open_watch_history)
    }, [])

    const loadMore = () => {
        if (items.length === 0) {
            return;
        }
        fetchMore({
            cursor: items[items.length - 1].updated_at,
        })
    }

    return <>
        <Box display='flex'
            pb={1}
            mr={1}
            ml={1}
            alignItems='center'>
            <Typography variant='h6'
                style={{ flex: 1 }}
                align='left'><strong>Your Watch History</strong></Typography>
            <UnstyledLink to='/history/saved'>
                <Button
                    size='small'
                    variant='contained'
                    color='secondary'
                    style={{ marginRight: 8 }}
                    startIcon={<BookmarkIcon />}>Saved</Button>
            </UnstyledLink>
            <UnstyledLink to='/v'>
                <Button variant='outlined'
                    size='small'
                    endIcon={<NextIcon />}
                    color='primary'>All Videos</Button>
            </UnstyledLink>
        </Box>
        <Grid
            container
            spacing={2} justify='center'>
            {items.map(item => <Grid
                item
                key={item.video.id}
                xs={12}
                sm={6}
                md={4}>
                <VideoCard
                    {...item.video}
                />
            </Grid>)}
        </Grid>

        {!loading && items.length === 0 ? <EmptyListComponent
            icon={<WatchHistoryIcon />}
            message='Your watched videos will show up here.'
        /> : null}

        {has_more ? <LoadingButton
            loading={loading}
            onClick={loadMore}
            fullWidth>Load more</LoadingButton> : <>
                <Typography
                    gutterBottom
                    align='center'>End of Watched Videos...</Typography>
                <UnstyledLink to='/v'>
                    <Button
                        fullWidth
                        endIcon={<NextIcon />}
                        color='primary'>See All Videos</Button>
                </UnstyledLink>
            </>}

    </>
}
