import { Chip, createStyles, makeStyles, Typography } from "@material-ui/core";
import PlayIcon from '@material-ui/icons/PlayCircleFilled';
import { Link } from "@reach/router";
import React from "react";
import { ImageSize, LazyImage } from "../image-upload";
import { UnstyledLink } from "../ui/unstyled-link";
import { SearchModel } from "./search.model";
import parse from 'html-react-parser';


interface Props extends SearchModel {
    item: {
        video_id: number;
        id: number;
        name: string;
        video_name: string;
        cover_image_key?: string;
        track: {
            id: number;
            name: string;
        }
        video_profiles?: {
            id: number;
            profile: {
                id: number;
                name: string;
                profile_image_key?: string;
            }
        }[];
    };
    onOpen?: () => void;
}

export const VideoRenderer = ({
    item,
    highlight,
    onOpen,
}: Props) => {
    const classes = useStyles({});


    const HTMLDom = React.useMemo(() => {
        if (!highlight) {
            return null;
        }
        const { description, transcript } = highlight;
        if (!description && !transcript) {
            return null;
        }
        return parse((description || transcript || []).join(' ').replaceAll('<em>', '<strong>').replaceAll('</em>', '</strong>'));
    }, [highlight])

    const [overline, title] = React.useMemo(() => {
        if (['presentation', 'lecture'].indexOf(item.name.toLowerCase()) > -1) {
            return [, item.video_name];
        }
        return [item.video_name, item.name];
    }, [item])

    return <UnstyledLink to={`/v/${item.video_id}?ck=${item.id}`} onClick={onOpen}>
        <a className={classes.card}>
            <div className={classes.banner}>
                <LazyImage
                    image_key={item.cover_image_key}
                    image_opt={{ size: ImageSize.large, aspect_ratio: 'none' }}
                    className={'image'}
                />
                <PlayIcon />
            </div>
            <div className={classes.content}>
                <div className={classes.header}>
                    {overline ? <Typography variant='overline'>{overline}</Typography> : null}
                    {title ? <Typography>{title}</Typography> : null}
                    <Typography variant='body2'>
                        {item.track.name}
                    </Typography>
                </div>
                {HTMLDom}
                <div className={classes.tags}>
                    {(item.video_profiles || []).map(({ profile }) => {
                        return <Chip
                            label={profile.name}
                            icon={<LazyImage
                                image_opt={{
                                    size: ImageSize.small,
                                }}
                                className='profile'
                                image_key={profile.profile_image_key}
                                placeholder={'/images/avatar.jpg'}
                            />}
                            key={profile.id}
                            variant={'default'}
                            className='profileTag'
                        />
                    })}
                </div>
            </div>
        </a>
    </UnstyledLink>
}

const useStyles = makeStyles(theme => createStyles({
    card: {
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        textDecoration: 'none',
        color: 'inherit',
        alignItems: 'center',
        padding: theme.spacing(2),
        '&:hover': {
            background: theme.palette.divider,
        },
    },
    banner: {
        width: '100%',
        maxWidth: 200,
        display: 'block',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        overflow: 'hidden',
        transition: 'all .2s ease',
        '& .image': {
            width: '100%',
            objectFit: 'contain',
        },
        '& .duration': {
            background: theme.palette.secondary.main,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(.5, 1),
            color: 'white',
            width: 'fit-content',
            position: 'absolute',
            whiteSpace: 'nowrap',
            right: theme.spacing(1),
            bottom: theme.spacing(1.5),
        },
        '& svg': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 2,
            color: 'white',
            background: 'black',
            borderRadius: '50%',
            padding: theme.spacing(1),
            width: 50,
            height: 50,
            transform: 'translate(-50%, -50%)',
            opacity: .4,
        },
    },
    content: {
        padding: theme.spacing(0, 2),
        flex: 1,
    },
    progress: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
    },
    progressBar: {
        height: theme.spacing(1),
    },
    header: {
        paddingBottom: theme.spacing(1),
        '& p': {
            fontWeight: theme.typography.fontWeightBold,
        },
    },
    tags: {
        marginLeft: theme.spacing(-.5),
        marginRight: theme.spacing(-.5),
        marginTop: theme.spacing(1),
        '& .tag': {
            margin: theme.spacing(.5),
            background: theme.palette.primary.main,
            color: 'white',
        },
        '& .profileTag': {
            margin: theme.spacing(.5),
            background: theme.palette.grey[900],
            color: 'white',
            '& .profile': {
                width: 30,
                height: 30,
                borderRadius: '50%',
            }
        },
    },
}))