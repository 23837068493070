import { Button, Container, createStyles, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { environment } from "../environment";
import { UnstyledLink } from "../ui/unstyled-link";

type Props = {
}

export const AppFooter = ({
}: Props) => {
    const classes = useStyles({});
    return <nav className={classes.header}>
        <Container maxWidth='lg'>
            <div className={classes.content}>
                <Button target='_blank' href={`${environment.frontend_url}/privacy-policy`}>Privacy</Button>
                <Button target='_blank' href={`${environment.frontend_url}/terms-of-service`}>Terms of service</Button>
                <div className='spacer' style={{ flex: 1 }} />
                <img src={'/images/icon.svg'} alt='lf-icon' className={classes.icon} />
            </div>
            <Typography align='center' variant='h6'>&copy; edropin &middot; {new Date().getFullYear()}</Typography>
        </Container>
    </nav >
}

const useStyles = makeStyles(theme => createStyles({
    header: {
        height: 300,
        paddingBottom: 50,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        '& button': {
            padding: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        '& h6': {
            paddingRight: theme.spacing(4),
        },
        '& a': {
            padding: theme.spacing(2, 4),
        }
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .spacer': {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            '& .spacer': {
                display: 'block',
            },
        }
    },
    icon: {
        width: '100%',
        maxWidth: 100,
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    }
}))