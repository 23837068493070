import { gql, useMutation } from "@apollo/client";
import { Button, createStyles, DialogContent, FormHelperText, makeStyles, Typography } from "@material-ui/core";
import NextIcon from '@material-ui/icons/ChevronRight';
import React from "react";
import { useAppDialog } from "../../../app-dialog";
import { CertUserDetailFragment } from "../../../models/cert-user-detail.model";
import { VideoQuestionModel } from "../../../models/quiz.model";
import { BasicDialog, BasicDialogActions, BasicDialogTitle } from "../../../ui/dialog";
import { LoadingButton } from "../../../ui/loading-button";
import { QuizQuestion } from "./quiz-question";
import { QuizResponse } from "./quiz-response";

const Mutation = gql`
    mutation submit_quiz($object: submit_quiz_input!) {
        submit_quiz(object: $object) {
            grade
            responses
        }
    }
`;

type Props = {
    cert_user_id: number;
    questions: VideoQuestionModel[];
    onClose: () => void;
    quiz_response?: VideoQuestionModel[];
    quiz_grade?: number;
    pass_rate: number;
}

export const VideoQuiz = ({
    cert_user_id,
    questions,
    onClose,
    quiz_response,
    quiz_grade,
    pass_rate,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [redo, setRedo] = React.useState(false);
    const [submit, submit_status] = useMutation(Mutation);

    const [response, setResponse] = React.useState<{
        grade: number; responses: VideoQuestionModel[]
    } | undefined>(quiz_response ? {
        responses: quiz_response || [],
        grade: quiz_grade || 0,
    } : undefined);

    const [selection, setSelection] = React.useState<{
        [id: string]: string[],
    }>({});


    const total = questions.length;
    const percent = Math.ceil((response?.grade || 0) / total * 100);


    const onSelect = (question_id: number, selection: string[]) => {
        setSelection(s => ({
            ...s,
            [question_id]: selection,
        }))
    }

    const submitAnswers = async () => {
        try {
            const { data } = await submit({
                variables: {
                    object: {
                        cert_user_id,
                        selection,
                    },
                }
            });
            if (data?.submit_quiz) {
                setResponse({
                    responses: data.submit_quiz.responses,
                    grade: data.submit_quiz.grade,
                });
                app_dialog.showSnackbar('Quiz submitted');
                setRedo(false);
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }


    const render = () => {
        if (response && !redo) {
            const {
                grade: score,
                responses,
            } = response;
            return <>
                <DialogContent>
                    <Typography
                        color={percent >= pass_rate ? 'secondary' : 'primary'}
                        variant='h3'><strong>{percent}%</strong></Typography>
                    <Typography
                        variant='body1'>{percent >= pass_rate ? 'QUIZ PASSED' : 'QUIZ NOT PASSED'}</Typography>
                    {percent >= pass_rate ? null : <Typography
                        gutterBottom variant='body2'>
                        Please review your quiz answers. You must score at least <strong>{pass_rate}%</strong> to get your certificate.
                </Typography>}
                    {(responses || []).map(question => <QuizResponse key={question.id}
                        question={question}
                    />)}
                </DialogContent>
                <BasicDialogActions>
                    <Button
                        onClick={() => setRedo(true)}>Review Quiz</Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={onClose}
                        endIcon={<NextIcon />}>
                        Finish
            </Button>
                </BasicDialogActions>
            </>
        }
        return <>
            <DialogContent>
                <Typography
                    gutterBottom
                    variant='body1'>Please complete this quiz in order to get your certificate.
                    You are able to review answers after you submit your quiz.</Typography>
                {questions.map(question => <QuizQuestion key={question.id}
                    question={question}
                    selected={selection[question.id] || []}
                    onSelect={onSelect}
                />)}
            </DialogContent>
            <BasicDialogActions>
                {Object.keys(selection).length !== questions.length ? <div >
                    <FormHelperText style={{ textAlign: 'right' }} error>Please make sure you have answered all questions</FormHelperText>
                </div> : null}
                <LoadingButton
                    variant='contained'
                    onClick={submitAnswers}
                    disabled={Object.keys(selection).length !== questions.length}
                    color='primary'
                    loading={submit_status.loading}>
                    Submit Answers
            </LoadingButton>
            </BasicDialogActions>
        </>
    }

    return <BasicDialog
        id='quiz-dialog'
        open={true}
        onClose={onClose}>
        <BasicDialogTitle title='Certificate quiz' onClose={!!quiz_response ? onClose : undefined} />
        {render()}
    </BasicDialog >
}

const useStyles = makeStyles(theme => createStyles({
    root: {
        padding: theme.spacing(2),
        margin: theme.spacing(4, 0),
        background: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
    },
    buttons: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(0, -.5),
        '& button': {
            margin: theme.spacing(0, .5),
        }
    },
}))