import { Container, createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { ContentBlock } from "./content-block"


type Props = {

}

export const HowDoesItWork = (props: Props) => {
    const classes = useStyles({});
    return <div className={classes.wrapper}>
        <div className='content'>
            <Container maxWidth='lg'>
                <Typography variant='h2' align='center'
                    gutterBottom><strong>How Does Edropin Work?</strong></Typography>
                <ContentBlock
                    id='content'
                    reverse
                    size={6}
                    image_src={'/images/tracks-image.jpg'}
                    title={`🔥 Unlock 300 Hours+ of Dental Content`}
                    content={`When you join, you will have access to all 300 hours+ of curated and PACE approved Dental Videos to watch right away.\nAll videos are sorted into tracks like Operative Dentistry, Endodontics, Implant surgery and more.`}
                />
                <ContentBlock
                    id='credits'
                    size={6}
                    image_src={'/images/credits-photo.jpg'}
                    title='😎 Earn Fractional CE For Watching'
                    content={`Now you can watch short 10-15 minute videos and earn fractional CE. For example if you watch a 15 minute video, you will earn 0.25 CE. We call this awesome tech MicroCredits`}
                />
                <ContentBlock
                    id='export'
                    reverse
                    size={6}
                    image_src={'/images/cert-export.jpg'}
                    title='⭐️ Export CE Whenever You Need'
                    content={`When you need to submit your CE credits, just export the amount you need. We will tally your fractional credits, so that you earn a whole number on your PACE certificate.`}
                />
            </Container>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    wrapper: {
        // marginTop: -100,
        '& .content': {
            paddingTop: theme.spacing(4),
        },
        overflowX: 'hidden',
    },
}))