import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { ProvideApollo } from './apollo';
import { ProvideAppDialog } from './app-dialog/use-app-dialog';
import { AppRouter } from './router';
import theme from './theme';
import { TopErrorBoundary } from './ui/errors';


function App() {
	

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopErrorBoundary>
        <ProvideAppDialog>
          <ProvideApollo>
            <AppRouter />
          </ProvideApollo>
        </ProvideAppDialog>
      </TopErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
