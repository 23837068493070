export const PROD_CONFIG = {
  production: true,
  image_base_url: 'https://d1niz8ad8nu5h5.cloudfront.net',
  image_bucket: 'edropin-images-1',
  ws_url: 'wss://ed-up-graphql.herokuapp.com/v1/graphql',
  http_url: 'https://ed-up-graphql.herokuapp.com/v1/graphql',
  default_page_size: 30,
  frontend_url: 'https://edropin.com',
  app_frontend_url: 'https://app.edropin.com',
  stripe_key: 'pk_test_51Hild2H43yBH2xuReJurciTIr6KsaCfimWxFHxTNJRyqBRqcYxqSxTqM0YQJA43XuipV7QmcZBTz21gojvUEblRF006hQ3Q9xA',
  firebaseConfig: {
    apiKey: "AIzaSyADCNk-7t5YvlWva4A3QnUoUmMBh8i8Jso",
    authDomain: "edropin-amalgam.firebaseapp.com",
    projectId: "edropin-amalgam",
    storageBucket: "edropin-amalgam.appspot.com",
    messagingSenderId: "442287750514",
    appId: "1:442287750514:web:15ebd5f8d2b8148a5af1eb",
    measurementId: "G-EDQX5QCXYZ"
  },
  app_store_link: 'https://apps.apple.com/ca/app/edropin/id1496980314',
  play_store_link: 'https://apps.apple.com/ca/app/edropin/id1496980314',
};
