import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import { Slide } from "react-reveal";
import { textWithLineBreaks } from "../../utils/text-with-line-breaks";


type Props = {
    id: string;
    image_src?: string;
    title: string;
    content: string;
    reverse?: boolean;
    size?: number;
}

export const ContentBlock = ({
    id,
    image_src,
    title,
    content,
    reverse,
    size = 8,
}: Props) => {
    const classes = useStyles({});
    return <div id={id} className={classes.content}>
        <Grid container spacing={3} alignItems='center' direction={reverse ? 'row-reverse' : 'row'}>
            {!image_src ? null : <Grid item xs={12} sm={6} lg={(12 - size) as any}>
                <img src={image_src} className={classes.image} />
            </Grid>}
            <Grid item xs={12} sm={!image_src ? 12 : 6} lg={!image_src ? 12 : size as any}>
                <Typography variant='h3' className={classes.title} align={!image_src ? 'center' : 'left'}>
                    {title}
                </Typography>
                <Typography variant='body1' className={classes.body} align={!image_src ? 'center' : 'left'}>
                    {textWithLineBreaks(content)}
                </Typography>
            </Grid>
        </Grid>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    content: {
        padding: theme.spacing(8, 0),
    },
    image: {
        maxWidth: '100%',
    },
    title: {
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: theme.spacing(3),
    },
    body: {
        fontSize: '1.5em',
    }
}))