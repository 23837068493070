import { UserProfileFragment, UserProfileModel } from "./user-profile.model";

export type UserProfileDetailModel = {
	id: string;
	created_at: string;
	name: string;
	profile_image_key?: string;
	bio?: string;
	user_follower_tally?: {
		id: string;
		total_followers?: number;
	};
	user_following_tally?: {
		id: string;
		total_following?: number;
	}
	referred_by?: UserProfileModel;
};

export const UserProfileDetailFragment = `
	id
	name
	created_at
	profile_image_key
	bio
	user_follower_tally {
		id
		total_followers
	}
	user_following_tally {
		id
		total_following
	}
	referred_by {
		${UserProfileFragment}
	}
`;