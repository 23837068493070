import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import clsx from 'clsx';
import moment from "moment";
import React from "react";
import { CertUserDetailModel } from "../../models/cert-user-detail.model";
import { InviteHeader } from "../invite/invite-header";
import { VideoQuiz } from "./video-quiz";

type Props = {
    refetch: () => void;
    certificate: CertUserDetailModel;
}

const PASS_RATE = 80;

export const CertificateQuiz: React.FC<Props> = ({
    refetch,
    certificate,
    children,
}) => {
    const classes = useStyles({});
    const [show_quiz, setShowQuiz] = React.useState(false);

    React.useEffect(() => {
        setShowQuiz(!certificate.quiz_completed_at);
    }, [certificate.quiz_completed_at])

    const total = ((certificate.cert_video_questions || []).length)
    const percent = total === 0 ? 100 : Math.ceil((certificate.quiz_grade || 0) / total * 100);

    const onClose = () => {
        refetch();
        setShowQuiz(false)
    }

    if (!certificate.cert_video_questions) {
        return null;
    }

    return <>
        {show_quiz ? <VideoQuiz
            cert_user_id={certificate.id}
            onClose={onClose}
            quiz_response={certificate.quiz_response}
            quiz_grade={certificate.quiz_grade}
            pass_rate={PASS_RATE}
            questions={(certificate.cert_video_questions || []).map(q => q.video_question)}
        /> : null}
        {certificate.quiz_completed_at ? <>
            {percent >= PASS_RATE ? <>
                {/* <InviteHeader title={'Certificate below 👇 Don\'t forget to share :)'} /> */}
                {children}
            </> : null}
            {total > 0 ? <div className={clsx(classes.quiz, {
                [classes.passed]: percent >= PASS_RATE
            })}>
                <Typography variant='h3'><strong>{percent}%</strong></Typography>
                <Typography variant='body1'><strong>{percent >= PASS_RATE ? 'QUIZ PASSED 🙌' : 'QUIZ NOT PASSED'}</strong></Typography>
                {percent >= PASS_RATE ? null : <Typography
                    gutterBottom variant='body2'>
                    Please review your quiz answers. You must score at least <strong>{PASS_RATE}%</strong> to get your certificate.
                </Typography>}
                <Typography
                    gutterBottom
                    variant='body2'>Completed {moment(certificate.quiz_completed_at).calendar()}</Typography>
                <Button
                    color='secondary'
                    onClick={() => setShowQuiz(true)}
                    variant='contained' size='small'>View Quiz Results</Button>
            </div> : null}
        </> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    quiz: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        marginBottom: theme.spacing(2),
    },
    passed: {
        backgroundColor: theme.palette.success.main,
    }
}))