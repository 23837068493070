import { Container, createStyles, makeStyles } from "@material-ui/core";
import clsx from 'clsx';
import React from "react";
import ReactPlayer from "react-player";

type Props = {

}

export const MicroCreditsVideo = (props: Props) => {
    const classes = useStyles({});
    const [is_playing, setIsPlaying] = React.useState(false);

    return <Container maxWidth='md'>
        <div className={classes.player} onClick={() => setIsPlaying(p => !p)}>
            <img
                src='/images/microcredits.jpg' className={clsx(
                    classes.cover,
                    { [classes.coverHidden]: is_playing },
                )} />
            <ReactPlayer
                url={`https://youtu.be/T5weFLPwkOE`}
                width='100%'
                height='100%'
                playing={is_playing}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                loop
                controls
            />
        </div>
    </Container>
}

const useStyles = makeStyles(theme => createStyles({
    player: {
        width: '100%',
        height: 0,
        paddingTop: '56.25%',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[4],
        overflow: 'hidden',
        transition: 'all .3s ease',
        cursor: 'pointer',
        '&:hover': {
            transform: 'scale(1.01)',
        },
        '& iframe': {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
        },
    },
    cover: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        zIndex: 2,
        opacity: 1,
        transition: 'all .3s ease',
    },
    coverHidden: {
        opacity: 0,
    },
}))