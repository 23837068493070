import { Router } from "@reach/router";
import React from "react";
import { NotFoundPage } from "../pages/404";
import { CertificateDetailPage } from "../pages/certificate-detail";
import HomePage from "../pages/home";
import InvitePage from "../pages/invite";
import MePage from "../pages/me";
import SignoutPage from "../pages/signout";
import { LoadingDots } from "../ui/loading-dots";
import { ManageScroll } from "./manage-scroll";

const AccountRouter = React.lazy(() => import('./account.router'));
const VideoRouter = React.lazy(() => import('./video.router'));
const TrackRouter = React.lazy(() => import('./track.router'));
const ProfileRouter = React.lazy(() => import('./profile.router'));
const CreditsRouter = React.lazy(() => import('./credits.router'));
const HistoryRouter = React.lazy(() => import('./history.router'));
const EWorkRouter = React.lazy(() => import('./ework.router'));
const UserRouter = React.lazy(() => import('./user.router'));

export const AppRouter = () =>
	<React.Suspense fallback={<LoadingDots />}>
		<Router>
			<SignoutPage path='/signout' />
			{/* <HomePage path='/' /> */}
			{/* <InvitePage path='/invite' /> */}
			{/* <AccountRouter path='/account/*' /> */}
			{/* <VideoRouter path='/v/*' /> */}
			{/* <TrackRouter path='/t/*' /> */}
			{/* <ProfileRouter path='/p/*' /> */}
			{/* <HistoryRouter path='/history/*' /> */}
			{/* <EWorkRouter path='/work/*' /> */}
			<CertificateDetailPage path='/cert/:certificate_id' />
			<CreditsRouter path='/*' />
			<CreditsRouter path='/credits/*' />
			<UserRouter path='/u/*' />
			<MePage path='/me' />
			<NotFoundPage default />
		</Router>
		<ManageScroll />
	</React.Suspense>