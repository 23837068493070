export type UserProfileModel = {
	id: string;
	name: string;
	created_at: string;
	profile_image_key?: string;
};

export const UserProfileFragment = `
	id
	name
	created_at
	profile_image_key	
`;