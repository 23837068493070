import { Box, Button, createStyles, lighten, Link, makeStyles, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from "moment";
import React from "react"
import { CertUserDetailModel } from "../../models/cert-user-detail.model";


type Props = {
    units: number;
    cert_user: CertUserDetailModel;
}

export const RCDSOSubmission = ({
    units,
    cert_user,
}: Props) => {
    const classes = useStyles({});

    const instructions = React.useMemo(() => {
        if (cert_user.video_licensure) {
            return [
                <Typography>1. <Link target='_blank' href='https://eportfolio.rcdso.org/createcourse'>Visit your ePortfolio</Link></Typography>,
                <Typography>2. For category please select <strong>Category {cert_user.video_licensure.rcdso_category}</strong>.</Typography>,
                <Typography>3. Select course title, please select <strong>{cert_user.video_licensure.title}</strong>. If there are multiple options, make sure you select the one where the Approved Sponsor is eDropin.</Typography>,
                <Typography>4. Confirm that the approved sponsor is <strong>eDropin</strong></Typography>,
                <Typography>5. Mark the completion date as <strong>{moment(cert_user.created_at).format('LL')}</strong>.</Typography>,
            ]
        }
        return [
            <Typography>1. <Link target='_blank' href='https://eportfolio.rcdso.org/createcourse'>Visit your ePortfolio</Link>.</Typography>,
            <Typography>2. For category please select <strong>Category {cert_user.track.rcdso_category}</strong>.</Typography>,
            <Typography>3. For activity type please select <strong>Course, Lecture, Seminar</strong>.</Typography>,
            <Typography>4. Use the following course title <strong>{`${cert_user.track.name} with edropin`}</strong>.</Typography>,
            <Typography>5. For topic please select <strong>{cert_user.track.name}</strong> . If the topic doesn't exist, select the closest category.</Typography>,
            <Typography>6. For sponsor name please enter <strong>eDropin</strong></Typography>,
            <Typography>7. Mark the completion date as <strong>{moment(cert_user.created_at).format('LL')}</strong>.</Typography>,
            <Typography>8. For points please enter <strong>{cert_user.units}</strong>.</Typography>
        ];
    }, [cert_user])

    return <div className={classes.submission}>
        <Alert severity='info'>
            <AlertTitle>How to submit your CE to RCDSO?</AlertTitle>
            {instructions.map((i, idx) => <Box py={.5} key={idx}>{i}</Box>)}
        </Alert>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    submission: {
        // background: lighten(theme.palette.success.light, .5),
        // border: `2px solid ${theme.palette.success.dark}`,
        // borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(2, 0),
        // padding: theme.spacing(2),
    }
}))