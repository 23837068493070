
export enum SearchType {
    track,
    video,
    profile,
}

export type SearchModel = {
    type: SearchType;
    item: any;
    highlight?: {
        description?: string[];
        transcript?: string[];
    }
}

