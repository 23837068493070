import { createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ImageSize, LazyImage } from "../image-upload";
import { UnstyledLink } from "../ui/unstyled-link";
import { getTruncatedText } from "../utils/get-truncated-text";
import { SearchModel } from "./search.model";

interface Props extends SearchModel {
    onOpen?: () => void;
    item: {
        id: number;
        profile_image_key?: string;
        name: string;
        bio?: string;
    }
}

export const ProfileRenderer = ({
    item,
    onOpen,
}: Props) => {
    const classes = useStyles({});
    return <UnstyledLink to={`/p/${item.id}`} onClick={onOpen}>
        <a className={classes.profile}>
            <div className={classes.avatar}>
                <LazyImage
                    image_key={item.profile_image_key}
                    className='image'
                    placeholder={'/avatar.jpg'}
                    image_opt={{ size: ImageSize.large }}
                />
            </div>
            <div className={classes.content}>
                <Typography variant='overline'>Official Profile</Typography>
                <Typography variant='h6'>{item.name}</Typography>
                <Typography variant='body1'>{getTruncatedText(item.bio, 200)}</Typography>
            </div>
        </a>
    </UnstyledLink>
}

const useStyles = makeStyles(theme => createStyles({
    profile: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        '&:hover': {
            background: theme.palette.divider,
        },
    },

    avatar: {
        width: '100%',
        maxWidth: 200,
        display: 'block',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        overflow: 'hidden',
        transition: 'all .2s ease',
        '& .image': {
            width: '100%',
            objectFit: 'contain',
            borderRadius: '50%',
        }
    },

    content: {
        flex: 1,
        padding: theme.spacing(0, 2),
    }
}))