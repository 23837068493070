import { gql, useQuery } from "@apollo/client";
import React from "react";
import { ActionType } from "../action-manager/action-manager";
import { useUser } from "../auth/use-user";
import { useDebounceValue } from "../utils/use-debounce-value";
import { SearchModel, SearchType } from "./search.model";


const SearchQuery = gql`
query search($query: String!) {
   search(object: {
       query: $query
   }) {
       hits
   }
}
`


export const useSearch = () => {
    const [searchQuery, setSearchQuery] = React.useState('');
    const [last_query, setLastQuery] = React.useState<string | null>(null);
    const query = useDebounceValue(searchQuery, 600);
    const { manager } = useUser();
    const { data, loading, refetch } = useQuery(SearchQuery, {
        variables: {
            query,
        }
    });
    const hits = (data?.search?.hits || []) as any[]

    const items = React.useMemo(() => {
        return hits.map(({
            _source: h,
            highlight,
        }) => {
            const type = h.item_type === 'track' ? SearchType.track :
                h.item_type === 'video_module' ? SearchType.video :
                    SearchType.profile;
            return {
                type,
                item: h,
                highlight,
            };
        });
    }, [hits]) as SearchModel[];

    React.useEffect(() => {
        if (loading || items.length > 3 || query === last_query) {
            return;
        }
        setLastQuery(query);
        manager.insertAction(ActionType.search, {
            query,
            total_results: items.length,
        })
    }, [loading, items])

    return {
        searchQuery,
        setSearchQuery,
        loading,
        items,
        refetch,
        empty: items.length === 0,
    }
}