import { WorkStatus } from "../models/job.model"

export type AuthUserModel = {
    id: string;
    name: string;
    updated_at: string;
    certificate_name?: string;
    profile_image_key?: string;
    bio?: string;
    email: string;
    state_id: number;
    pace_id?: string;
    opt_out_offer?: boolean;
    current_video_module_id?: number;
    is_member: boolean;
    field_id?: number;
    referral_uuid?: string;
    field?: {
        id: number;
        name: string;
    };
    is_intro_shown?: boolean;
    agree_terms_at?: string;
    flags?: {[id: string]: any};
    stripe_customer_id?: string;
    work_status: WorkStatus;
    state: {
        id: number;
        name: string;
        country_id: number;
    }
}

export const AuthUserFragment = `
	id
    name
    updated_at
    certificate_name
    bio
	email
    profile_image_key
    state_id
    pace_id
    field_id
    flags
    field {
        id
        name
    }
    opt_out_offer
    current_video_module_id
    is_member
    agree_terms_at
    stripe_customer_id
    referral_uuid
    is_intro_shown
    work_status
    state {
        id
        name
        country_id
    }
`