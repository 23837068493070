import { createStyles, makeStyles } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import React from "react"
import { useUser } from "../../auth/use-user"
import { AppLayout } from "../../layout/app-layout"
import { WatchHistory } from "../history/history"
import { UserProfile } from "../user-profile/user-profile"

type Props = {

}

export const MePage = (props: RouteComponentProps<Props>) => {
    const classes = useStyles({}); 
    const { user_id } = useUser();
    return <AppLayout>
        <UserProfile user_profile_id={user_id} />
        <div className={classes.section}>
            <WatchHistory />
        </div>
    </AppLayout>
}


const useStyles = makeStyles(theme => createStyles({
    section: {
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(4),
        borderTop: `1px solid ${theme.palette.divider}`,
    }
}))