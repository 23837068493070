import { gql, useSubscription } from '@apollo/client';
import { Box, Button, Container, Link, Typography } from '@material-ui/core';
import React from "react";
import { ActionManager, useActionManager } from '../action-manager/action-manager';
import { WalkthroughDialog } from '../components/walkthrough-dialog';
import firebase from '../firebase';
import OnBoardPage from '../pages/onboard';
import { GuestLayout } from '../pages/signin/guest-layout';
import { ErrorPage } from '../ui/errors';
import { LoadingDots } from '../ui/loading-dots';
import { AuthUserFragment, AuthUserModel } from './auth-user.model';

type UserContextModel = {
	user_id: string;
	user: AuthUserModel;
	loading?: boolean;
	manager: ActionManager;
}


const SUBSCRIBE_TO_USER = gql`
	subscription user($user_id: String!) {
		user_by_pk(id: $user_id) {
			${AuthUserFragment}		
		}
	}
`;

const UserContext = React.createContext<UserContextModel>({} as any);

type Props = {
	user_id: string;
}

export const ProvideUser: React.FC<Props> = ({ user_id, children }) => {

	const { data, loading, error } = useSubscription(SUBSCRIBE_TO_USER, {
		variables: {
			user_id,
		}
	});
	const user = data?.user_by_pk as AuthUserModel;
	const manager = useActionManager();

	const show_onboard = React.useMemo(() =>
		!user || !user.agree_terms_at || !user.is_member || !user.field_id, [
		user?.is_member, user?.agree_terms_at
	])

	if (loading) {
		return <LoadingDots />;
	}

	if (show_onboard) {
		return <OnBoardPage
			user={user}
		/>
	}

	if (!user) {
		return <ErrorPage
			title='Something went wrong'
			message='Your account is not available right now. Please contact us at help@edropin.com if this continues.'
		/>
	}
	if (user.flags?.limit) {
		return <GuestLayout>
			<Container maxWidth='sm'>
				<Box textAlign='center' pt={4}>
					<Typography variant='h4' gutterBottom>Welcome to Edropin.</Typography>
					<Typography gutterBottom variant='h6'>Please visit <Link href='http://core.edropin.com'>http://core.edropin.com</Link> to evaluate courses. You
				may be asked to log in again with the same email: {user.email}</Typography>
					<Button variant='contained'
						color='secondary' onClick={() => firebase.auth().signOut()}>Sign Out</Button>
				</Box>

			</Container>
		</GuestLayout>
	}
	return <UserContext.Provider value={{
		user_id,
		loading: loading,
		user,
		manager,
	}}>
		{!user.is_intro_shown ? <WalkthroughDialog /> : null}
		{children}
	</UserContext.Provider>
}

export const useUser = (): UserContextModel => {
	return React.useContext(UserContext);
}