import { createStyles, makeStyles, Typography } from "@material-ui/core"
import React from "react"


type Props = {
    index?: number
}

const cards = [{
    src: 'https://randomuser.me/api/portraits/women/1.jpg',
    text: 'Edropin is a no-brainer for any dentist. Tremendous value and it\'s free.',
    who: 'Dr. Sarah, Arizona',
}, {
    src: 'https://randomuser.me/api/portraits/men/46.jpg',
    text: 'I took over $3,000 worth of CE on Edropin last year, all for a crazy low price of $299',
    who: 'Dr. Mark, Toronto',
}, {
    src: 'https://randomuser.me/api/portraits/men/3.jpg',
    text: 'There is always new content being added, and they really make sure the content is top-notch!',
    who: 'Dr. Blake, London',
}, {
    src: 'https://randomuser.me/api/portraits/women/3.jpg',
    text: 'Edropin has made me hate any course that isn\'t available on-demand. I watch late nights after I finish work.',
    who: 'Dr. Kate, Pennsylvania ',
}, {
    src: 'https://randomuser.me/api/portraits/men/30.jpg',
    text: 'I never have to wait for my CE credits. I jump around and watch however long I like and still earn credits.',
    who: 'Dr. Stevens, Alberta',
}];

export const HeroCard = ({
    index = 0,
}: Props) => {
    const classes = useStyles({});
    const card = cards[index];
    return <div className={classes.card}>
        <img src={card.src} alt={card.who} />
        <div className='content'>
            <Typography gutterBottom variant='body1'>{card.text}</Typography>
            <Typography variant='body2' color='textSecondary'>- {card.who}</Typography>
        </div>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    card: {
        color: theme.palette.text.primary,
        '& img': {
            borderRadius: '100%',
            width: 60,
            height: 60,
            zIndex: 2,
        },
        '& .content': {
            background: 'white',
            marginTop: -25,
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[4],
            padding: theme.spacing(3, 2, 2, 2),
            width: 350,
        },
    }
}))