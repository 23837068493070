import { gql, useMutation } from "@apollo/client";
import { Box, Link, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as yup from 'yup';
import { useAppDialog } from "../../app-dialog";
import { AuthUserModel } from "../../auth/auth-user.model";
import { environment } from "../../environment";
import firebase from '../../firebase';
import { AccountFragment } from "../../models/account.model";
import { EdContainer } from "../../ui/ed-container";
import { ProfileImageEditor } from "../../ui/profile-image-editor";
import { GuestLayout } from "../signin/guest-layout";
import { OnboardForm } from "./onboard-edit-form";
import { WelcomeHeader } from "./welcome-header";

type Props = {
    user?: AuthUserModel;
}

const MeValidationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    state_id: yup.number().required('Please select your state'),
    field_id: yup.number().required('Please select a field'),
    bio: yup.string(),
    pace_id: yup.string(),
    agree_terms: yup.boolean()
        .required("The terms and conditions must be accepted")
        .oneOf([true], "The terms and conditions must be accepted"),
    send_newsletter: yup.boolean(),
});

const InsertUser = gql`
    mutation insert_user($object: onboard_input!) {
        onboard(object: $object) {
            user {
                ${AccountFragment}
            }
        }
    }
`;

export const OnBoardPage = ({
    user,
}: Props) => {
    const app_dialog = useAppDialog();
    const is_signout = window.location.href.indexOf('signout') > -1;
    const [insert, status] = useMutation(InsertUser);
    const rid = React.useMemo(() => localStorage.getItem('rid') as string | undefined, []);


    const onSubmit = async ({
        send_newsletter,
        agree_terms,
        ...values
    }: any) => {
        try {
            const { data } = await insert({
                variables: {
                    object: {
                        ...values,
                        agree_terms_at: new Date().toISOString(),
                        opt_out_offer: !send_newsletter,
                        rid,
                    },
                }
            });
            if (data?.onboard?.user) {
                if ((window as any).fbq) {
                    (window as any).fbq('track', 'OnboardEvent', { email: user?.email, rid });
                }
                app_dialog.showSnackbar(data.onboard.user.is_member ? 'Welcome to edropin' : 'Account reserved');
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    React.useEffect(() => {
        if (is_signout) {
            (async () => {
                await firebase.auth().signOut();
                window.location.href = '/';
            })();
        }
    }, [is_signout])

    return <GuestLayout>
        <WelcomeHeader />
        {!!user && !!user.agree_terms_at && !!user.is_member && !!user.field_id ? <EdContainer max_width='sm'>
            <Typography variant='h6'
                align='center'
                gutterBottom><span role='img'>🎉</span> We've reserved your
                account for you and we'll email you as soon as your account is ready.</Typography>
            <Typography
                variant='body1'
                gutterBottom
                align='center'>
                To learn more about <strong>edropin</strong> you can
                read our <Link href={`${environment.frontend_url}/blog/1`}>blog post</Link>. Thank you!
            </Typography>
            <Box pt={4}>
                <Typography
                    gutterBottom
                    align='center'><strong>edropin</strong> is a community of peers. Share a profile photo. <span role='img'>📸</span></Typography>
                <ProfileImageEditor
                    user={user} />
            </Box>
        </EdContainer> : <EdContainer max_width='md'>
                <Typography variant='h6'
                    align='center'
                    gutterBottom><strong>edropin</strong> is a community of dentists, specialists, students, hygienists and dental team members.</Typography>
                <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom
                    align='center'>
                    All non-dental accounts will be removed.
            </Typography>
                <Formik
                    initialValues={{
                        name: user?.name || '',
                        bio: user?.bio || '',
                        email: user?.email || '',
                        state_id: user?.state_id || '',
                        field_id: user?.field_id || '',
                        pace_id: user?.pace_id || '',
                        opt_out_offer: user?.opt_out_offer || false,
                        send_newsletter: user ? !user.opt_out_offer : false,
                        agree_terms: user ? !!user.agree_terms_at : false,
                    }}
                    validationSchema={MeValidationSchema}
                    onSubmit={onSubmit}>{props => <Form>
                        <OnboardForm {...props}
                            loading={status.loading}
                            country_id={user?.state?.country_id}
                        />
                    </Form>}
                </Formik>
            </EdContainer>}
    </GuestLayout>
}
