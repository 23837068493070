import { Button, Container, createStyles, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { HeroCard } from "./hero-card";


type Props = {
}


export const Hero = ({
}: Props) => {
    const classes = useStyles({});
    const theme = useTheme();
    return (<>
        <div id='hero' className={classes.content}>
            <div className={classes.bottom}>
                <HeroCard index={3} />
            </div>
            <div className={classes.bottomCenter}>
                <HeroCard />
            </div>
            <div className={classes.bottomRight}>
                <HeroCard index={4} />
            </div>
            <Container maxWidth='md'>
                <Typography variant='h4' className={classes.title}>
                    You've been invited to <br />
                    <span className='huge'>Edropin</span><br />
                </Typography>
                <Typography variant='body1' gutterBottom className={classes.body}>
                    It's like Netflix for Dentistry | PACE Approved
                </Typography>
                <div className={classes.buttons}>
                    <Button size='large'
                        fullWidth
                        href='/?state=signup&napp=1'
                        className='join' variant='contained'
                        color='primary'>JOIN AND EARN CE FOR FREE</Button>
                </div>
            </Container>
        </div >
        <div className={classes.wave}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill={theme.palette.primary.main} fillOpacity="1" d="M0,64L120,101.3C240,139,480,213,720,234.7C960,256,1200,224,1320,208L1440,192L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>        </div>
    </>);
}

const useStyles = makeStyles(theme => createStyles({
    linethrough: {
        color: theme.palette.grey[400],
        textDecoration: 'line-through',
    },
    content: {
        padding: theme.spacing(6, 0, 6, 0),
        textAlign: 'center',
        background: theme.palette.primary.main,
        color: 'white',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(6, 0, 12, 0),
        },
    },
    topLeft: {
        position: 'absolute',
        left: 20,
        top: 20,
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    },
    bottomCenter: {
        position: 'absolute',
        left: '40%',
        bottom: -180,
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        },
    },
    middleRight: {
        position: 'absolute',
        right: 20,
        top: '8%',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    },
    bottom: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: -120,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        [theme.breakpoints.up('md')]: {
            left: '10%',
            transform: 'none'
        },

    },
    bottomRight: {
        position: 'absolute',
        right: 20,
        bottom: '-30%',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            right: '5%',
            display: 'block',

        }
    },
    title: {
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: theme.spacing(3),
        '& .huge': {
            fontSize: '1.5em',
            [theme.breakpoints.up('sm')]: {
                fontSize: '2em',
            }
        }
    },
    body: {
        fontSize: '1.4em',
    },
    buttons: {
        paddingTop: theme.spacing(2),
        maxWidth: 500,
        width: '100%',
        margin: '0 auto',
        '& .join': {
            padding: theme.spacing(2, 4),
            background: theme.palette.background.default,
            color: 'black',
            '&:hover': {
                background: 'white',
            }
        },
    },
    wave: {
        marginTop: -2,
        '& svg': {
            display: 'block',
        }
    },
}))