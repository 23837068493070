import React from "react"
import { RouteComponentProps } from "@reach/router"
import { GuestLayout } from "../signin/guest-layout"
import { Hero } from "./hero"
import { GuestSignup } from "./guest-signup"
import { HowDoesItWork } from "./how-does-it-work"
import { GuestLearnMore } from "./learn-more"

type Props = {

}

export const GuestInvitePage = (props: RouteComponentProps<Props>) => {
    return <GuestLayout>
        <Hero />
        <HowDoesItWork />
        <GuestSignup />
        <GuestLearnMore />
    </GuestLayout>
}
