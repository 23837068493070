import { Box, Container, createStyles, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useAppDialog } from "../../app-dialog";
import firebase from '../../firebase';
import { authErrors } from "../signin/firebase-auth-errors";
import { SignupForm } from "../signin/signup-form";


type Props = {

}

export const GuestSignup = (props: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const [loading, setLoading] = React.useState(false);

    const onSubmit = async (values: { email: string; password: string; }) => {
        setLoading(true);
        try {
            await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
        } catch (e) {
            const code = e.code.split('/');
            let message = 'Something went wrong on our end. Please contact help@edropin.com if this continues';
            if (code.length === 2) {
                const error = authErrors[code[1]];
                if (error) {
                    message = error;
                }
            }

            app_dialog.showDialog({
                title: 'Failed to create account',
                message,
                buttons: [],
            })

        } finally {
            setLoading(false);
        }
    }
    return <div className={classes.wrapper}>
        <Container maxWidth='lg' id='about'>
            <Typography
                gutterBottom
                align='center'
                variant='h2' component='h2'>
                Ready to Join? <strong>It's Free</strong></Typography>
            <Typography
                align='center'
                variant='h6'>Unlock 300 hours+ of dental content & earn CE for $0</Typography>
            <Box pt={2} pb={4} maxWidth={500} margin='0 auto'>
                <SignupForm onSubmit={onSubmit}
                    loading={loading} />
            </Box>
        </Container>
    </div>
}

const useStyles = makeStyles(theme => createStyles({
    title: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.h3.fontSize,
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.h2.fontSize,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.h1.fontSize,
        },

    },
    wrapper: {
        background: '#fff5f5',
        padding: theme.spacing(10, 0),
    }
}))