import { Box, createStyles, FormHelperText, Link, makeStyles, Typography } from '@material-ui/core';
import { ErrorMessage, Field, FormikProps } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React from 'react';
import { environment } from '../../environment';
import { FieldPicker } from '../../ui/field-picker';
import { LoadingButton } from '../../ui/loading-button';
import { StatePickerField } from '../../ui/state-picker-field';

interface Props extends FormikProps<any> {
	loading?: boolean;
	country_id?: number;
	show_referral?: boolean;
}

export const OnboardForm = ({
	loading,
	country_id,
	isValid,
	submitCount,
}: Props) => {
	const classes = useStyles({});

	return <div className={classes.root}>
		<Field
			component={TextField}
			name="name"
			type="text"
			label="Full name (required)"
			fullWidth
			margin='normal'
		/>
		<Field
			component={StatePickerField}
			name="state_id"
			country_id={country_id}
		/>
		<FieldPicker
			name="field_id"
			variant='outlined'
			label="Select Dental Field (required)"
			fullWidth
			margin='normal'
		/>
		<Field
			component={TextField}
			name="bio"
			type="text"
			label="Bio (Optional)"
			placeholder='Share a bit about yourself...'
			fullWidth
			margin='normal'
			multiline
		/>
		<Field
			component={TextField}
			name="pace_id"
			type="text"
			label="PACE # (Optional)"
			helperText='Add your PACE ID for Certificates'
			fullWidth
			margin='normal'
		/>

		<Field
			name='agree_terms'
			type="checkbox"
			color='inherit'
			className={classes.checkbox}
			component={CheckboxWithLabel}
			Label={{
				label: (<>
					I understand and agree with edropin's <Link
						href={`${environment.frontend_url}/terms`}
						target='_blank'>Terms and Conditions</Link> and <Link
							href={`${environment.frontend_url}/privacy`}
							target='_blank'>Privacy Policy</Link>.
					<ErrorMessage name='agree_terms' render={error => <FormHelperText
						error>{error}</FormHelperText>} />
				</>),
			}}
		/>
		<Field
			name='send_newsletter'
			type="checkbox"
			color='inherit'
			className={classes.checkbox}
			component={CheckboxWithLabel}
			Label={{
				label: (<>
					I would like to be notified of new relevant content that <strong>I care about</strong> is released on edropin.
				</>),
			}}
		/>

		<Box pt={2} textAlign='right'>
			<LoadingButton
				loading={loading}
				variant='contained'
				color='primary'
				type='submit'
				size='large'
			>
				Get Started
			</LoadingButton>
			{!isValid && submitCount > 0 ? <Typography className={classes.error} variant='body1'>
				Please check for fields marked in red
		</Typography> : null}
		</Box>
	</div>
}

const useStyles = makeStyles((theme) => createStyles({
	root: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	checkbox: {
		alignSelf: 'flex-start',
		marginTop: -5,
		marginBottom: theme.spacing(1),
	},
	error: {
		color: theme.palette.error.main,
		fontWeight: theme.typography.fontWeightBold,
	}
}));