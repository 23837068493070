import { gql, useQuery } from "@apollo/client";
import { Box, Button, createStyles, makeStyles } from "@material-ui/core";
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { RouteComponentProps } from "@reach/router";
import React from "react";
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { useUser } from "../../auth/use-user";
import { AppLayout } from "../../layout/app-layout";
import { CertUserDetailFragment, CertUserDetailModel } from "../../models/cert-user-detail.model";
import { ErrorComponent } from "../../ui/errors";
import { LoadingDots } from "../../ui/loading-dots";
import { PageHeader } from "../../ui/page-header";
import { TabBar } from "../../ui/tab-bar";
import { CertificateQuiz } from "./certificate-quiz";
import { ClaimHistory } from "./claim-history";
import { PaceCertificate } from "./pace-certificate";
import { PaceSubmission } from "./pace-submission/pace-submission";
import { RCDSOCertificate } from "./rcdso-certificate";
import { RCDSOSubmission } from "./rcdso-submission";

type Props = {
    certificate_id: number;
}


const QUERY_GET_DOWNLOAD_URL = gql`
    query certificate_download_url($id: bigint!) {
        get_cert_download_url(id: $id) {
            pace_download_url
            rcdso_download_url
        }
    }
`;

export const CertificateDetailPage = ({
    certificate_id,
}: RouteComponentProps<Props>) => {
    const classes = useStyles({});
    const { user: { state_id } } = useUser();
    const { data } = useQuery(QUERY_GET_DOWNLOAD_URL, {
        variables: {
            id: certificate_id,
        }
    });
    const {
        entity: certificate,
        loading,
        error,
        refetch,
    } = useGetByPk<CertUserDetailModel>({
        entity_name: 'cert_user',
        pk_columns: [{
            name: 'id',
            value: certificate_id,
            type: 'bigint',
        }],
        fragment: CertUserDetailFragment,
    });

    const _tabs = React.useMemo(() => {
        if (state_id !== 11) {
            return [{
                id: 0,
                label: 'PACE',
            }, {
                id: 1,
                label: 'RCDSO (Ontario)',
            }]
        }
        return [{
            id: 0,
            label: 'RCDSO (Ontario)',
        }, {
            id: 1,
            label: 'PACE',
        }]
    }, [state_id])

    const pace_download_url = data?.get_cert_download_url?.pace_download_url;
    const rcdso_download_url = data?.get_cert_download_url?.rcdso_download_url;


    const [title, speaker_name, units, rcdso_category] = React.useMemo(() => {
        if (!certificate) {
            return ['', '', 0, 'Category-3'];
        }
        const title = certificate.video_licensure?.title || `${certificate.track.name} with edropin`;
        const speaker_name = certificate.video_licensure?.speaker_name || 'edropin - Multiple speakers';
        const units = certificate.video_licensure ? certificate.units || 0 : Math.round(certificate?.credit_tally?.total_credit || 0);
        const rcdso_category = certificate.video_licensure && certificate.video_licensure.rcdso_category
            ? certificate.video_licensure.rcdso_category : `Category-${certificate.track.rcdso_category}`;
        return [title, speaker_name, units, rcdso_category];
    }, [certificate])

    const renderTab = (tab_id: number) => {
        if (!certificate) {
            return null;
        }
        const label = _tabs.find(t => t.id === tab_id)?.label;
        switch (label) {
            case 'PACE':
                return <>
                    <PaceSubmission
                        cert_user_id={certificate.id}
                        units={units}
                        title={title}
                        speaker_name={speaker_name}
                        created_at={certificate.created_at}
                        pace_code={certificate.track.pace_code}
                        pace_submission_id={certificate.pace_submission_id}
                    />
                    {pace_download_url ? <Button fullWidth
                        variant='contained'
                        color='primary'
                        href={pace_download_url}
                        target='_blank'
                        startIcon={<DownloadIcon />}>Download PACE Certificate as PDF</Button> : null}
                    <PaceCertificate
                        units={units}
                        title={title}
                        speaker_name={speaker_name}
                        created_at={certificate.created_at}
                        track_name={certificate.track.name}
                    />
                </>
            default:
                return <>
                    <RCDSOSubmission
                        units={units}
                        cert_user={certificate} />
                    {rcdso_download_url ? <Button fullWidth
                        variant='contained'
                        color='primary'
                        href={rcdso_download_url}
                        target='_blank'
                        startIcon={<DownloadIcon />}>Download RCDSO Certificate as PDF</Button> : null}
                    <RCDSOCertificate
                        units={units}
                        title={title}
                        speaker_name={speaker_name}
                        created_at={certificate.created_at}
                        track_name={certificate.track.name}
                        rcdso_category={rcdso_category}
                    />
                </>;
        }
    }

    return <AppLayout>
        <PageHeader
            title={certificate ? `${(certificate.video_licensure
                ? certificate.units : Math.round(certificate.credit_tally?.total_credit || 0))} CE` : 'Certificate'}
            subtitle={certificate ? certificate.track.name : undefined}
        />
        {error ? <ErrorComponent error={error} title='Failed to load certificate' /> : null}
        {loading ? <LoadingDots /> : null}
        {certificate ? <div className={classes.root}>
            <CertificateQuiz
                refetch={refetch}
                certificate={certificate}
            ><TabBar
                tabs={_tabs}
            >{({
                TabBarComponent,
                tab_id,
            }) => <>
                    {TabBarComponent}
                    <Box pt={2} pb={2}>
                        {renderTab(tab_id as number)}
                    </Box>
                </>}</TabBar>
            </CertificateQuiz>

            <ClaimHistory
                claims={certificate.video_module_credit_claims}
            />
        </div> : null}
    </AppLayout>
}



const useStyles = makeStyles(theme => createStyles({
    root: {
        position: 'relative',
    }
}))
