import { gql, useMutation } from "@apollo/client";
import { Button, createStyles, makeStyles, Typography } from "@material-ui/core";
import { Link } from "@reach/router";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import { useList } from "../../apollo/networking";
import { useGetByPk } from "../../apollo/networking/use-get-by-pk";
import { useAppDialog } from "../../app-dialog";
import { useUser } from "../../auth/use-user";
import { ImageSize, LazyImage } from "../../image-upload";
import { UserProfileDetailFragment, UserProfileDetailModel } from "../../models/user-profile-detail.model";
import { LoadingButton } from "../../ui/loading-button";
import { LoadingDots } from "../../ui/loading-dots";
import { UnstyledLink } from "../../ui/unstyled-link";
import { textWithLineBreaks } from "../../utils/text-with-line-breaks";
import { userAvatarPlaceholder } from "../../utils/user-avatar-placeholder";

const MutationInsertFollower = gql`
    mutation insert_follower($object: user_follower_insert_input!) {
        insert_user_follower_one(object: $object, on_conflict: {
            constraint: user_follower_pkey,
            update_columns: [deleted_at]
        }) {
            id
        }
    }
`;

type Props = {
    user_profile_id: string;
}

export const UserProfile = ({
    user_profile_id,
}: Props) => {
    const classes = useStyles({});
    const app_dialog = useAppDialog();
    const { user_id } = useUser();
    const [follow, follow_status] = useMutation(MutationInsertFollower);

    const {
        items,
        loading,
        error,
        refetch: profile_refetch,
    } = useList<UserProfileDetailModel>({
        entity_name: 'user_profile',
        fragment: UserProfileDetailFragment,
        clause_outer: `$user_id: String!`,
        query_inner: `
            where: {id: {_eq: $user_id}}
       `,
        variables: {
            user_id: user_profile_id,
        }
    });
    const {
        entity: user_follower,
        refetch,
    } = useGetByPk<{ id: number; deleted_at?: string; }>({
        entity_name: 'user_follower',
        fragment: `
            id
            deleted_at
        `,
        pk_columns: [{
            name: 'user_id',
            type: 'String',
            value: user_profile_id,
        }, {
            name: 'follower_id',
            type: 'String',
            value: user_id,
        }]
    })

    const user = React.useMemo(() => items.length > 0 ? items[0] : undefined, [items]);

    const is_following = React.useMemo(() =>
        !!user_follower && !user_follower?.deleted_at, [user_follower])

    const onFollow = async () => {
        try {
            await follow({
                variables: {
                    object: {
                        user_id: user_profile_id,
                        deleted_at: is_following ? 'now()' : null,
                    }
                }
            });
            refetch();
            profile_refetch();
            app_dialog.showSnackbar(`${is_following ? 'Unfollowed' : 'Following'} ${user?.name}`)
        } catch (e) {
            app_dialog.showError(e);
        }
    }

    const [followers, following] = React.useMemo(() => [
        user?.user_follower_tally?.total_followers || 0,
        user?.user_following_tally?.total_following || 0,
    ], [user]);


    return <>
        {loading ? <LoadingDots /> : null}
        {user ? <>
            <LazyImage
                image_opt={{
                    size: ImageSize.medium,
                }}
                image_key={user.profile_image_key}
                placeholder={userAvatarPlaceholder(user.name)}
                className={classes.avatar}
            />
            <Typography variant='h6'>{user.name}</Typography>
            <div className={classes.followBar}>
                {user_id === user_profile_id ? <UnstyledLink to={`/account/edit`}>
                    <Button variant='outlined'>Edit My Profile</Button>
                </UnstyledLink> : <LoadingButton
                    variant={is_following ? 'contained' : 'outlined'}
                    onClick={onFollow}
                    color='primary'
                    size='small'
                    loading={follow_status.loading}
                >{is_following ? 'Following' : 'Follow'}</LoadingButton>}
            </div>
            <div className={classes.followers}>
                <Link to={`/u/${user.id}/followers`}>
                    <Typography variant='body1' className='marginRight'>
                        <strong>{followers}</strong> {pluralize('follower', followers)}
                    </Typography>
                </Link>
                <Link to={`/u/${user.id}/following`}>
                    <Typography variant='body1'>
                        <strong>{following}</strong> following
                </Typography>
                </Link>
            </div>
            <Typography variant='body2'
                color='textSecondary'>{textWithLineBreaks(user.bio)}</Typography>
            <div className={classes.referral}>
                {user.referred_by ? <LazyImage
                    image_opt={{
                        size: ImageSize.medium,
                    }}
                    image_key={user.referred_by.profile_image_key}
                    placeholder={userAvatarPlaceholder(user.referred_by.name)}
                    className='avatar'
                /> : null}
                <div>
                    <Typography variant='body1'>Joined {moment(user.created_at).calendar()}</Typography>
                    {user.referred_by ? <Typography variant='body2'>
                        Invited by <Link to={`/u/${user.referred_by.id}`}><strong>{user.referred_by.name}</strong></Link>
                    </Typography> : null}
                </div>
            </div>
        </> : null}
    </>
}

const useStyles = makeStyles(theme => createStyles({
    avatar: {
        width: 100,
        height: 100,
        borderRadius: theme.shape.borderRadius,
    },
    followBar: {
        padding: theme.spacing(1, 0, 0, 0),
    },
    followers: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 0),
        '& .marginRight': {
            paddingRight: theme.spacing(2),
        }
    },
    referral: {
        padding: theme.spacing(2, 0),
        display: 'flex',
        alignItems: 'center',
        '& .avatar': {
            width: 50,
            height: 50,
            borderRadius: theme.shape.borderRadius,
            marginRight: theme.spacing(2),
        }
    }
}))